@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Love+Ya+Like+A+Sister&display=swap');
body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    color: #808080;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
  }

.btn-google {
    background: #4c8bf5;
    border-color: #4c8bf5;
    color: #fff;
    position: relative;
    &:hover {
        background: #357AE8;
        border-color: #357AE8;
        color: #eee;
    }
}

.btn-warning {
    color: #fff;
}

.btn-outline-warning:hover {
    color: #fff;
}

.logo {
    font-family: 'Love Ya Like A Sister', cursive;
    font-size: 30px;
}

.cloud-icon {
    max-width: 200px;
    margin: 0 auto;
}