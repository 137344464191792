.dropzone {
    min-height: 280px;
    border: 2px dashed #999;
    background: #eee;
    padding: 20px;
    border-radius: 4px;
}

.plus-icon {
    font-size: 8em;
}